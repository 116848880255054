import {getStock,exportStock ,getStockNum} from "./api.js";
import navList from "../navList";
import Util from "@/utils/utils";


export default {
  data() {
    return {
      expLoading:false,
      pageLoadFlag: false,
      navList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/myStock",
          name: "myStock",
          title: "当前库存"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: ""
      },
      count: {
        totalOccQty: 0,
        totalActQty: 0,
        totalAvaQty: 0
      },
      scroll: {
        // x: 'true',
        // y: 400
      },
      showTotalColumns: ["实际库存", "可用数量", "预留数量"],
      columns: [
        {
          label: "物料类型",
          prop: "WLtype",
          key: "WLtype",
          width: 180
        },
        {
          label: "产品型号",
          prop: "model",
          key: "model",
          sortable: "custom",
          sortName: "model",
          width: 180
        },
        {
          label: "颜色",
          prop: "colour",
          key: "colour",
          width: 90
        },
        {
          label: "物料组",
          prop: "materialGroupName",
          key: "materialGroupName",
          width: 150
        },
        {
          label: "销售组织",
          prop: "orgName",
          key: "orgName",
          width: 150
        },
        {
          label: "仓库",
          prop: "warehouseName",
          key: "warehouseName",
          width: 150
        },
        {
          label: "定价组",
          prop: "priceGroupName",
          key: "priceGroupName",
          width: 150
        },
        {
          label: "库存类别",
          prop: "invTypeName",
          key: "invTypeName",
          width: 200
        },
        {
          label: "库存状态",
          prop: "gicInvStatusName",
          key: "gicInvStatusName",
          width: 200
        },
        {
          label: "质量等级",
          prop: "invStatusName",
          key: "invStatusName",
          width: 200
        },
        {
          label: "补差类型",
          prop: "invStatusTypeName",
          key: "invStatusTypeName",
          width: 200
        },
        {
          label: "实际库存",
          prop: "totalBig",
          key: "totalBig",
          align: "right",
          width: 200
        },
        {
          label: "预留数量",
          prop: "bigQtyLock",
          key: "bigQtyLock",
          align: "right",
          width: 200
        },
        {
          label: "可用数量",
          prop: "bigQty",
          key: "bigQty",
          align: "right",
          width: 200
        },
        {
          label: "共享标识",
          prop: "share",
          key: "share",
          width: 200,
          slot: "share"
        },
        {
          label: "物料编码",
          prop: "materialCode",
          key: "materialCode",
          width: 200
        },
        {
          label: "货主名",
          prop: "ownerName",
          key: "ownerName",
          width: 200
        },
        {
          label: "货主编号",
          prop: "ownerNumber",
          key: "ownerNumber",
          width: 200
        },
        {
          label: "入库时间",
          prop: "inInvDate",
          key: "inInvDate",
          width: 200
        },
      ],
      data: [],
      dataAll:[],//全部数据
      terms: {
        model: "",
        colour: "",
        warehouseId: "",
        invStatusId: "",
        isLock: "",
        dealerMaterialGroupFlag: "",
        materialGroupCode: "",
        materialGroupName: "",
        gicWarehouseType: "",
        invStatusType: "",
        invType: "",
        bigQtyMin: "",
        selectBom:false,
      },
      taoji: true,
      danji: false,
      showLoading:false
    };
  },
  computed: {
    // 实际统计
    totalBig: function() {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.totalBig) || 0);
      }, 0);
    },
    // 可用统计
    bigQty: function() {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.bigQty) || 0);
      }, 0);
    },
    // 预留统计
    bigQtyLock: function() {
      return this.data.reduce((sum, item) => {
        return sum + (parseInt(item.bigQtyLock) || 0);
      }, 0);
    }
  },
  created() {
    this.navList = navList.nav1;
    this.getData();
    if(this.taoji) {
      this.getTaojiNum()
    }
  },
  methods: {
    taojiChang(type) {
      if (type == "taoji") {
        this.danji = !this.taoji
        this.getTaojiNum()
      } else {
        this.taoji = !this.danji;
      }
      this.pager.pageNo = 1;
      this.getData();
    },
    searchFun(arg) {
      this.terms = { ...this.terms,...arg[1] };
      this.pager.pageNo = 1;
      this.getData();
      if(this.taoji) {
        this.getTaojiNum()
      }
    },
    //排序change事件
    onTableChange(sorter) {
      const sortName = this.columns.filter(it => it.prop == sorter.prop);
      if (sortName.length < 1) {
        return;
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
          sorter.order == "ascending"
            ? "asc"
            : sorter.order == "descending"
            ? "desc"
            : "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    exportFile(){
      this.expLoading = true;
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          ...this.terms
        },
        page: {
          pageNo: 1,
          pageSize: 300000,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"

      };
      // 如果是套机查询 增加参数
      if(this.taoji) {
        data.terms.selectBom = false
      } else {
        data.terms.selectBom = ''
      }
      const url = this.taoji?'exportInventoryProductList&type=1':'exportInventoryList&type=1'
      exportStock(data,url).then(res => {
        Util.blobToJson(res.data).then(content=>{
          if(content&&content.msg=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'我的库存.xls')
        }).finally(()=>{
          this.expLoading = false;
        })
      }).catch(err=>{
        this.expLoading = false;
      });
    },
    getTaojiNum() {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          ...this.terms
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      getStockNum(data).then(res=>{
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        this.count.totalOccQty = data.data.totalOccQty;
        this.count.totalActQty = data.data.totalActQty;
        this.count.totalAvaQty = data.data.totalAvaQty;
      })
    },
    getData() {
      const data = {
        cisCode: this.$store.state.user.userInfo.customer.cisCode,
        _loading: true,
        terms: {
          ...this.terms
        },
        page: {
          pageNo: this.taoji?this.pager.pageNo:1,
          pageSize: this.taoji?this.pager.pageSize:10,
          sortName: this.pager.sortName,
          sortOrder: this.pager.sortOrder
        },
        loginType: "CS"
      };
      this.showLoading = true
      // 如果是套机查询 增加参数
      if(this.taoji) {
        data.terms.selectBom = false
      } else {
        data.terms.selectBom = ''
      }
      const url = this.taoji?'findMaterialInventoryPage':'findSingleMaterialInvList'
      getStock(data,url).then(res => {
        console.log('数据',JSON.parse(res.data))
        let data;
        if (typeof res.data == "string") {
          data = JSON.parse(res.data);
        } else {
          data = res.data;
        }
        if(data.data.length>0) {
          data.data.forEach(item=>{
            item.WLtype = (item.taoji?'套机':'单机')
          })
        }
        this.dataAll = data.data || [];
        if(this.taoji) {
          this.data = data.data || []
          this.pager.count =  data.page.totalCount;

        } else {
          const start = (this.pager.pageNo - 1) * this.pager.pageSize;
          data.data = data.data || [];
          this.data = data.data.slice(start, start + this.pager.pageSize);
          this.pager.count = data.data.length;
          this.count.totalOccQty = data.page.map.totalOccQty;
          this.count.totalActQty = data.page.map.totalActQty;
          this.count.totalAvaQty = data.page.map.totalAvaQty;
        }


      }).finally(()=>{
        this.showLoading = false
      });
    },
    //前端分页
    pageChangeGetData(pager){
      if(this.taoji) {
        this.pager.pageNo = pager.pageNo
        this.pager.pageSize = pager.pageSize
        this.getData()
      } else {
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        this.data = this.dataAll.slice(start, start + this.pager.pageSize);
      }

    }
  }
};
