import dmshttp from "@/utils/dmsrequest";

//我的库存列表
export function getStock(data,url) {
    return dmshttp({
        url: url,
        data
    });
}
//我的库存列表导出
export function exportStock(data,url) {
    return dmshttp({
        url: url,
        data,
        responseType: 'blob'
    });
}
//我的库存列表
export function getStockold(data) {
    return dmshttp({
        url: "findInventoryList",
        data
    });
}
//我的库存列表
export function getStockNum(data) {
    return dmshttp({
        url: "findTaojiInventoryTotal",
        data
    });
}